import React, {
  createContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react"
import axios from "axios"

// context initial state
const initialState = {
  CSAToken: "",
  currentUserPhone: "",
}

// context reducers
const appReducer = (state, action) => {
  switch (action.type) {
    case "RESTORE_TOKEN":
      return {
        ...state,
        CSAToken: action.payload,
      }
    case "USER_PHONE":
      return {
        ...state,
        currentUserPhone: action.payload,
      }
    default:
      return state
  }
}

export const GlobalContext = createContext(initialState)

export const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(appReducer, initialState)

  useEffect(() => {
    const getToken = async () => {
      try {
        const getTokenResponse = await axios.get(
          "https://www.universal-tutorial.com/api/getaccesstoken",
          {
            headers: {
              "api-token": `aYLkjU4oZbdsNa6gs4u41GSUX6JNm3gmY051fJl71xVyS6GuKgfttiYpcJRvoqkg6IM`,
              Accept: "application/json",
              "user-email": "aarif@digispoc.com",
            },
          }
        )
        if (getTokenResponse.status === 200) {
          dispatch({
            type: "RESTORE_TOKEN",
            payload: getTokenResponse.data?.auth_token,
          })
        }
      } catch (error) {
        console.log("Unable to get api token", error)
      }
    }
    getToken()
  }, [])

  const updateState = {
    updateUserPhone: data => {
      // console.log("context update", data)
      dispatch({
        type: "USER_PHONE",
        payload: data,
      })
    },
  }
  return (
    <GlobalContext.Provider value={{ ...state, ...updateState }}>
      {children}
    </GlobalContext.Provider>
  )
}
