import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.min.js"
import "./src/assets/css/typography.css"
import "./src/assets/css/global.css"

import wrapWithProvider from "./wrap-with-provider"

// export const shouldUpdateScroll = () => false

export const wrapRootElement = wrapWithProvider
// export const shouldUpdateScroll = ({
//   routerProps: { location },
//   getSavedScrollPosition,
// }) => {
//   const currentPosition = getSavedScrollPosition(location)
//   const queriedPosition = getSavedScrollPosition({ pathname: `/random` })
//   console.log({
//     shouldUpdateScroll: location,
//     getSavedScrollPosition,
//     currentPosition,
//     queriedPosition,
//   })
//   window.scrollTo(...(currentPosition || [0, 0]))

//   return true
// }
