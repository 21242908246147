exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-all-blogs-bkp-js": () => import("./../../../src/pages/all-blogs_bkp.js" /* webpackChunkName: "component---src-pages-all-blogs-bkp-js" */),
  "component---src-pages-all-event-js": () => import("./../../../src/pages/all-event.js" /* webpackChunkName: "component---src-pages-all-event-js" */),
  "component---src-pages-all-faq-js": () => import("./../../../src/pages/all-faq.js" /* webpackChunkName: "component---src-pages-all-faq-js" */),
  "component---src-pages-all-genes-js": () => import("./../../../src/pages/all-genes.js" /* webpackChunkName: "component---src-pages-all-genes-js" */),
  "component---src-pages-all-media-js": () => import("./../../../src/pages/all-media.js" /* webpackChunkName: "component---src-pages-all-media-js" */),
  "component---src-pages-all-publications-js": () => import("./../../../src/pages/all-publications.js" /* webpackChunkName: "component---src-pages-all-publications-js" */),
  "component---src-pages-bioinformatics-bkp-file-js": () => import("./../../../src/pages/bioinformatics_bkp_file.js" /* webpackChunkName: "component---src-pages-bioinformatics-bkp-file-js" */),
  "component---src-pages-bioinformatics-blogs-bkp-js": () => import("./../../../src/pages/bioinformatics/blogs_bkp.js" /* webpackChunkName: "component---src-pages-bioinformatics-blogs-bkp-js" */),
  "component---src-pages-bioinformatics-js": () => import("./../../../src/pages/bioinformatics.js" /* webpackChunkName: "component---src-pages-bioinformatics-js" */),
  "component---src-pages-bioinformatics-knowmore-js": () => import("./../../../src/pages/bioinformatics/knowmore.js" /* webpackChunkName: "component---src-pages-bioinformatics-knowmore-js" */),
  "component---src-pages-bioinformatics-seeking-to-js": () => import("./../../../src/pages/bioinformatics/seeking-to.js" /* webpackChunkName: "component---src-pages-bioinformatics-seeking-to-js" */),
  "component---src-pages-blogs-bkp-js": () => import("./../../../src/pages/blogs_bkp.js" /* webpackChunkName: "component---src-pages-blogs-bkp-js" */),
  "component---src-pages-breast-assure-index-js": () => import("./../../../src/pages/breast-assure/index.js" /* webpackChunkName: "component---src-pages-breast-assure-index-js" */),
  "component---src-pages-breast-assure-thankyou-js": () => import("./../../../src/pages/breast-assure/thankyou.js" /* webpackChunkName: "component---src-pages-breast-assure-thankyou-js" */),
  "component---src-pages-campaign-terms-condition-js": () => import("./../../../src/pages/campaign-terms&condition.js" /* webpackChunkName: "component---src-pages-campaign-terms-condition-js" */),
  "component---src-pages-campaigns-consumer-genomics-carrier-risk-ghi-js": () => import("./../../../src/pages/campaigns/consumer-genomics-carrier-risk-ghi.js" /* webpackChunkName: "component---src-pages-campaigns-consumer-genomics-carrier-risk-ghi-js" */),
  "component---src-pages-career-apply-now-js": () => import("./../../../src/pages/career/apply-now.js" /* webpackChunkName: "component---src-pages-career-apply-now-js" */),
  "component---src-pages-career-index-js": () => import("./../../../src/pages/career/index.js" /* webpackChunkName: "component---src-pages-career-index-js" */),
  "component---src-pages-consentform-js": () => import("./../../../src/pages/consentform.js" /* webpackChunkName: "component---src-pages-consentform-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cookies-js": () => import("./../../../src/pages/cookies.js" /* webpackChunkName: "component---src-pages-cookies-js" */),
  "component---src-pages-diagnostics-js": () => import("./../../../src/pages/diagnostics.js" /* webpackChunkName: "component---src-pages-diagnostics-js" */),
  "component---src-pages-disorder-generequest-js": () => import("./../../../src/pages/disorder/generequest.js" /* webpackChunkName: "component---src-pages-disorder-generequest-js" */),
  "component---src-pages-disorder-number-of-genes-js": () => import("./../../../src/pages/disorder/number-of-genes.js" /* webpackChunkName: "component---src-pages-disorder-number-of-genes-js" */),
  "component---src-pages-disorder-testgeneform-js": () => import("./../../../src/pages/disorder/testgeneform.js" /* webpackChunkName: "component---src-pages-disorder-testgeneform-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-events-poll-form-js": () => import("./../../../src/pages/events/poll-form.js" /* webpackChunkName: "component---src-pages-events-poll-form-js" */),
  "component---src-pages-events-register-form-js": () => import("./../../../src/pages/events/register-form.js" /* webpackChunkName: "component---src-pages-events-register-form-js" */),
  "component---src-pages-gallerydetail-js": () => import("./../../../src/pages/gallerydetail.js" /* webpackChunkName: "component---src-pages-gallerydetail-js" */),
  "component---src-pages-gene-details-js": () => import("./../../../src/pages/gene-details.js" /* webpackChunkName: "component---src-pages-gene-details-js" */),
  "component---src-pages-genetic-counselling-js": () => import("./../../../src/pages/genetic-counselling.js" /* webpackChunkName: "component---src-pages-genetic-counselling-js" */),
  "component---src-pages-genomic-wellness-js": () => import("./../../../src/pages/genomic-wellness.js" /* webpackChunkName: "component---src-pages-genomic-wellness-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-job-details-js": () => import("./../../../src/pages/job-details.js" /* webpackChunkName: "component---src-pages-job-details-js" */),
  "component---src-pages-leadership-[name]-js": () => import("./../../../src/pages/leadership/[name].js" /* webpackChunkName: "component---src-pages-leadership-[name]-js" */),
  "component---src-pages-leadership-index-js": () => import("./../../../src/pages/leadership/index.js" /* webpackChunkName: "component---src-pages-leadership-index-js" */),
  "component---src-pages-maatri-seq-enq-form-js": () => import("./../../../src/pages/MaatriSeq/EnqForm.js" /* webpackChunkName: "component---src-pages-maatri-seq-enq-form-js" */),
  "component---src-pages-maatri-seq-index-js": () => import("./../../../src/pages/MaatriSeq/index.js" /* webpackChunkName: "component---src-pages-maatri-seq-index-js" */),
  "component---src-pages-medical-newsletter-index-js": () => import("./../../../src/pages/medical-newsletter/index.js" /* webpackChunkName: "component---src-pages-medical-newsletter-index-js" */),
  "component---src-pages-medical-newsletter-thankyou-js": () => import("./../../../src/pages/medical-newsletter/thankyou.js" /* webpackChunkName: "component---src-pages-medical-newsletter-thankyou-js" */),
  "component---src-pages-newsletters-index-js": () => import("./../../../src/pages/newsletters/index.js" /* webpackChunkName: "component---src-pages-newsletters-index-js" */),
  "component---src-pages-newsletters-subscribe-js": () => import("./../../../src/pages/newsletters/subscribe.js" /* webpackChunkName: "component---src-pages-newsletters-subscribe-js" */),
  "component---src-pages-newsletters-thankyou-js": () => import("./../../../src/pages/newsletters/thankyou.js" /* webpackChunkName: "component---src-pages-newsletters-thankyou-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-rix-know-more-js": () => import("./../../../src/pages/Rix-know-more.js" /* webpackChunkName: "component---src-pages-rix-know-more-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-somatic-advantage-74-js": () => import("./../../../src/pages/Somatic-Advantage-74.js" /* webpackChunkName: "component---src-pages-somatic-advantage-74-js" */),
  "component---src-pages-strand-articles-js": () => import("./../../../src/pages/strand-articles.js" /* webpackChunkName: "component---src-pages-strand-articles-js" */),
  "component---src-pages-strand-genomic-health-insights-book-an-appointment-js": () => import("./../../../src/pages/strand-genomic-health-insights/Book-an-appointment.js" /* webpackChunkName: "component---src-pages-strand-genomic-health-insights-book-an-appointment-js" */),
  "component---src-pages-strand-genomic-health-insights-index-js": () => import("./../../../src/pages/strand-genomic-health-insights/index.js" /* webpackChunkName: "component---src-pages-strand-genomic-health-insights-index-js" */),
  "component---src-pages-strand-genomic-health-insights-parent-details-js": () => import("./../../../src/pages/strand-genomic-health-insights/parent-details.js" /* webpackChunkName: "component---src-pages-strand-genomic-health-insights-parent-details-js" */),
  "component---src-pages-strand-genomic-health-insights-parent-plus-program-js": () => import("./../../../src/pages/strand-genomic-health-insights/parent-plus-program.js" /* webpackChunkName: "component---src-pages-strand-genomic-health-insights-parent-plus-program-js" */),
  "component---src-pages-strand-genomic-health-insights-thankyou-js": () => import("./../../../src/pages/strand-genomic-health-insights/thankyou.js" /* webpackChunkName: "component---src-pages-strand-genomic-health-insights-thankyou-js" */),
  "component---src-pages-teams-js": () => import("./../../../src/pages/teams.js" /* webpackChunkName: "component---src-pages-teams-js" */),
  "component---src-pages-technology-js": () => import("./../../../src/pages/technology.js" /* webpackChunkName: "component---src-pages-technology-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */),
  "component---src-pages-testcatalog-js": () => import("./../../../src/pages/testcatalog.js" /* webpackChunkName: "component---src-pages-testcatalog-js" */),
  "component---src-pages-testimonial-js": () => import("./../../../src/pages/testimonial.js" /* webpackChunkName: "component---src-pages-testimonial-js" */),
  "component---src-pages-thankyou-js": () => import("./../../../src/pages/thankyou.js" /* webpackChunkName: "component---src-pages-thankyou-js" */),
  "component---src-pages-what-we-do-js": () => import("./../../../src/pages/what-we-do.js" /* webpackChunkName: "component---src-pages-what-we-do-js" */),
  "component---src-pages-why-we-are-successful-js": () => import("./../../../src/pages/why-we-are-successful.js" /* webpackChunkName: "component---src-pages-why-we-are-successful-js" */),
  "component---src-templates-bioabout-js": () => import("./../../../src/Templates/bioabout.js" /* webpackChunkName: "component---src-templates-bioabout-js" */),
  "component---src-templates-bioblogs-js": () => import("./../../../src/Templates/bioblogs.js" /* webpackChunkName: "component---src-templates-bioblogs-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/Templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-disorder-js": () => import("./../../../src/Templates/disorder.js" /* webpackChunkName: "component---src-templates-disorder-js" */),
  "component---src-templates-dxdetails-js": () => import("./../../../src/Templates/dxdetails.js" /* webpackChunkName: "component---src-templates-dxdetails-js" */),
  "component---src-templates-events-js": () => import("./../../../src/Templates/events.js" /* webpackChunkName: "component---src-templates-events-js" */),
  "component---src-templates-seekingto-js": () => import("./../../../src/Templates/seekingto.js" /* webpackChunkName: "component---src-templates-seekingto-js" */),
  "component---src-templates-team-js": () => import("./../../../src/Templates/team.js" /* webpackChunkName: "component---src-templates-team-js" */),
  "component---src-templates-test-js": () => import("./../../../src/Templates/test.js" /* webpackChunkName: "component---src-templates-test-js" */)
}

